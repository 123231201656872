'use strict'

/**
 * Validate if a value is a real number
 * From https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
 * With one change - not supporting numeric strings
 * @param {*} n
 * @returns {boolean}
 */
function isNumber(n) {
    return !Number.isNaN(n) && Number.isFinite(n)
}

/**
 * Validate if a string value is of the form '123.456' / '-123.456' / '+=123.456' / '-=123.456'
 * @param {*} n
 * @returns {boolean}
 */
function isRelativeNumber(n) {
    return typeof n === 'string' && /^(-|[+-]=)?\d*\.?\d+$/.test(n)
}

/**
 * Validate if a value is a number, a number string or a relative number (+=/-=)
 * @param {*} n
 * @returns {boolean}
 */
function isNumberLike(n) {
    return isNumber(+n) || isRelativeNumber(n)
}

/**
 * Validate if a value is a real object
 * Based on https://stackoverflow.com/a/14706877, Added check for isArray()
 * @param {*} obj
 * @returns {boolean}
 */
function isObject(obj) {
    const type = typeof obj
    return type === 'function' || type === 'object' && !Array.isArray(obj) && !!obj
}

const validateTypes = {
    string(key, validator, param) {
        return typeof param === 'string' && (!validator.enum || validator.enum.includes(param))
    },

    number(key, validator, param) {
        if (!isNumber(param)) {
            return false
        }
        const {min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER} = validator
        return param >= min && param <= max
    },

    numberLike(key, validator, param) {
        return isNumberLike(param)
    },

    boolean(key, validator, param) {
        return typeof param === 'boolean'
    },

    object(key, validator, param) {
        if (isObject(param)) {
            if (isObject(validator.properties)) {
                return validateSchema(validator.properties, param)
            }
            return true
        }
        return false
    }
}

function validateSchema(schema, params, logger) {
    return Object
        .entries(schema)
        .every(([key, validator]) => {
            const param = params[key]
            const validateType = validateTypes[validator.type]

            return typeof param !== 'undefined' && validateType ? validateType(key, validator, param, logger) : true
        })
}

module.exports = {
    validateSchema
}
